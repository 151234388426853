import './BookAppointmentsVerify.less'

import { Button } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { Schedule, ScheduleType } from '../../../../requests'
import { DatacBox, DatacIcon, DatacTitle } from '../../../common'

interface BookAppointmentsVerifyProps {
  schedule: Schedule
  selectedSlots: number[]
  timezone: string
  onEdit: () => void
  onConfirm: () => void
  countDown: number
  setCountDown: React.Dispatch<React.SetStateAction<number>>
}

export const BookAppointmentsVerify: React.VFC<BookAppointmentsVerifyProps> = ({
  schedule,
  selectedSlots,
  timezone,
  onEdit,
  onConfirm,
  countDown,
  setCountDown
}) => {
  const intl = useScopedIntl('')
  const intlVerify = useScopedIntl('subject_dashboard.appointment.book.verify')
  const [slotIndex, setSlotIndex] = useState<number>(null)

  useEffect(() => {
    function beforeUnload(e: BeforeUnloadEvent) {
      e.preventDefault()
    }
    window.addEventListener('beforeunload', beforeUnload)
    return () => {
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [])

  useEffect(() => {
    if (!schedule || !selectedSlots) return null

    if (schedule.type === ScheduleType.Sequential) {
      setSlotIndex(schedule.visits[0].slots.findIndex(s => s.id === selectedSlots[0]))
    }

    const myInterval = setInterval(intervalCallback, 1000)

    return () => clearInterval(myInterval)
  }, [schedule, selectedSlots])

  const intervalCallback = () => {
    setCountDown(c => (c <= 0 ? 0 : c - 1))
  }

  const secondsToString = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60

    return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`
  }

  const getSlot = (visitIndex: number) => {
    if (schedule.type === ScheduleType.Sequential) {
      return schedule.visits[visitIndex].slots[slotIndex]
    }

    return schedule.visits[visitIndex].slots.find(s => selectedSlots.includes(s.id))
  }

  const isDisabled = countDown === 0

  return (
    <div className="subject-dashboard-book-appointment-verify">
      <DatacTitle size="medium">{intlVerify('title')}</DatacTitle>
      {isDisabled ? (
        <div className="subject-dashboard-book-appointment-verify__expired">{intlVerify('expired')}</div>
      ) : (
        intlVerify('time_left', { time: secondsToString(countDown) })
      )}
      <DatacBox>
        <div className={classNames('subject-dashboard-book-appointment-verify__slots', { disabled: isDisabled })}>
          {(schedule?.type === ScheduleType.Open || slotIndex !== null) &&
            schedule?.visits?.map((v, index) => {
              const slot = getSlot(index)
              if (!slot) return null

              const getStartTime = () => {
                const timeDate = `${slot.date.format('YYYY-MM-DD')}T${slot.startTime}`
                const dateInOriginalTimezone = dayjs.tz(timeDate, v.timezone)

                return dateInOriginalTimezone.tz(timezone).format('HH:mm')
              }

              return (
                <div key={index} className="subject-dashboard-book-appointment-verify__slots__visit">
                  <div className="subject-dashboard-book-appointment-verify__slots__visit__left">
                    <div className="subject-dashboard-book-appointment-verify__slots__visit__left__dot" />
                  </div>
                  <div className="subject-dashboard-book-appointment-verify__slots__visit__date">
                    <div className="subject-dashboard-book-appointment-verify__slots__visit__date__day">
                      {slot.date.format('DD')}
                    </div>
                    {slot.date.format('MMM')}
                  </div>
                  <div className="subject-dashboard-book-appointment-verify__slots__visit__info">
                    <DatacTitle size="small">
                      {slot.date.format('dddd')}, {getStartTime()} -{' '}
                      {dayjs(getStartTime(), 'HH:mm').add(v.duration, 'minutes').format('HH:mm')}
                    </DatacTitle>
                    {v.title}
                  </div>
                </div>
              )
            })}
        </div>
        <div
          className={classNames('subject-dashboard-book-appointment-verify__informations', {
            disabled: isDisabled
          })}
        >
          <div className="subject-dashboard-book-appointment-verify__informations__row">
            <DatacIcon name="fileText" raw /> {schedule?.study?.title}
          </div>
          <div className="subject-dashboard-book-appointment-verify__informations__row">
            <DatacIcon name="disc" raw /> {schedule?.visits?.[0]?.centerName}
          </div>
        </div>
      </DatacBox>

      <div className="subject-dashboard-book-appointment-verify__footer">
        <div className="subject-dashboard-book-appointment-verify__footer__wrapper">
          {isDisabled ? (
            <Button onClick={onEdit} type="primary">
              {intlVerify('book_again')}
            </Button>
          ) : (
            <>
              <Button onClick={onEdit} type="default">
                {intl('common.edit')}
              </Button>
              <Button onClick={onConfirm} type="primary">
                {intlVerify('book')}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
