import './BookAppointmentsEnroll.less'

import { Button } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useContext } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { routes } from '../../../../routes'
import { UserContext } from '../../../auth'
import { DatacIcon, DatacTitle } from '../../../common'

export const BookAppointmentsEnroll: React.VFC = () => {
  const intlVerify = useScopedIntl('subject_dashboard.appointment.book.verify')
  const { user } = useContext(UserContext)

  return (
    <div className="subject-dashboard-book-appointment-enroll">
      <DatacIcon name="check" type="blue" />
      <DatacTitle>{intlVerify('booking_confirmed')}</DatacTitle>
      {user?.email && <div>{intlVerify('email_sent')}</div>}
      {user?.email && <div>{user.email}</div>}
      <Button type="default" onClick={() => navigate(routes.subjectDashboard)}>
        {intlVerify('back')}
      </Button>
    </div>
  )
}
