import React from 'react'

import { BookAppointments } from '../../components/SubjectDashboard/BookAppointments'
import { AuthRoute } from '../../components/auth/AuthRoute'
import { SessionTimeoutModal } from '../../components/auth/SessionTimeoutModal'
import { AccountType } from '../../requests'

const IndexPage = () => {
  return (
    <>
      <SessionTimeoutModal accountType={AccountType.Subject} />
      <AuthRoute accountType={AccountType.Subject}>
        <BookAppointments />
      </AuthRoute>
    </>
  )
}

export default IndexPage
export const Head = () => <title>Datacapt - Book appointments</title>
