import './BookAppointmentsNotAvailable.less'

import { Button } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { updateParticipantNonAvailable } from '../../../../requests'
import { routes } from '../../../../routes'
import { DatacIcon, DatacMessage, DatacTitle } from '../../../common'

interface BookAppointmentsNotAvailableProps {
  goBackToSchedule: () => void
  scheduleId: number
}

export const BookAppointmentsNotAvailable: React.VFC<BookAppointmentsNotAvailableProps> = ({
  goBackToSchedule,
  scheduleId
}) => {
  const intl = useScopedIntl('')
  const intlNotAvailable = useScopedIntl('subject_dashboard.appointment.book.not_available')

  const setParticipantNonAvailable = () => {
    updateParticipantNonAvailable(
      { scheduleId },
      {
        onSuccess: () => navigate(routes.subjectDashboard),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  return (
    <div className="subject-dashboard-book-appointment-not-available">
      <DatacIcon name="check" type="red" />
      <DatacTitle>{intlNotAvailable('title')}</DatacTitle>
      <div>{intlNotAvailable('description')}</div>
      <Button type="primary" onClick={() => setParticipantNonAvailable()}>
        {intlNotAvailable('accept')}
      </Button>
      <Button type="default" onClick={goBackToSchedule}>
        {intlNotAvailable('cancel')}
      </Button>
    </div>
  )
}
