import './BookAppointments.less'

import dayjs from 'dayjs'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { Schedule, bookVisits, fetchScheduleForSubject, removeBlockedBookings } from '../../../requests'
import { routes } from '../../../routes'
import { getParsedHash, localeFromPath } from '../../../utils'
import { DatacMessage } from '../../common'
import { Layout } from '../Layout'
import { BookAppointmentsEnroll } from './BookAppointmentsEnroll'
import { BookAppointmentsErrorPage } from './BookAppointmentsErrorPage'
import { BookAppointmentsNotAvailable } from './BookAppointmentsNotAvailable'
import { BookAppointmentsSchedule } from './BookAppointmentsSchedule'
import { BookAppointmentsSteps, BookingStep } from './BookAppointmentsSteps'
import { BookAppointmentsVerify } from './BookAppointmentsVerify'

dayjs.updateLocale(localeFromPath(), { weekStart: 1 })
const countDownSeconds = 5 * 60

export const BookAppointments: React.VFC = () => {
  const intl = useScopedIntl('')
  const intlVerify = useScopedIntl('subject_dashboard.appointment.book.verify')
  const [currentStep, setCurrentStep] = useState<BookingStep>(null)
  const [recruitmentId, scheduleIdFromUrl, hadSurvey] = getParsedHash()
  const [schedule, setSchedule] = useState<Schedule>(null)
  const [isFetchingSchedule, setIsFetchingSchedule] = useState(true)
  const [selectedSlots, setSelectedSlots] = useState<number[]>([])
  const [timezone, setTimezone] = useState(dayjs.tz.guess())
  const [countDown, setCountDown] = useState(countDownSeconds)

  useEffect(() => {
    fetchScheduleForSubject(
      { recruitmentId, scheduleId: scheduleIdFromUrl },
      {
        onSuccess: schedule => {
          if (schedule.visits.find(v => !v.slots?.filter(s => s.isAvailable).length)) {
            setCurrentStep(BookingStep.NoSlots)
          } else {
            setCurrentStep(BookingStep.Schedule)
            setSchedule(schedule)
            setIsFetchingSchedule(false)
            setCountDown(countDownSeconds)
          }
        },
        onSubjectRejected: () => setCurrentStep(BookingStep.Rejected),
        onSubjectAlreadyBooked: () => setCurrentStep(BookingStep.AlreadyBooked),
        onScheduleNotPublished: () => setCurrentStep(BookingStep.ScheduleNotPublished),
        onSubjectDidNotApplied: () => navigate(routes.subjectDashboard), // TODO: we should redirect to landing page, but we don't know to which one. flow has a hole
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingSchedule(false)
        }
      }
    )
  }, [])

  const onEdit = () => {
    removeBlockedBookings(
      { scheduleId: schedule.id },
      {
        onSuccess: () => {
          setSelectedSlots([])
          setCurrentStep(BookingStep.Schedule)
          setCountDown(countDownSeconds)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const onBookVisits = (isOnlyBlocking: boolean) => {
    bookVisits(
      { isOnlyBlocking, scheduleId: schedule.id, slotIds: selectedSlots },
      {
        onSuccess: () =>
          setCurrentStep(isOnlyBlocking ? BookingStep.ScheduleConfirmation : BookingStep.ScheduleSuccess),
        onAlreadyTaken: () => {
          DatacMessage.error(intlVerify('slot_taken.title'), intlVerify('slot_taken.description'))
          window.location.reload()
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const showErrorPage = [
    BookingStep.Rejected,
    BookingStep.NoSlots,
    BookingStep.AlreadyBooked,
    BookingStep.ScheduleNotPublished
  ].includes(currentStep)

  return (
    <Layout
      hasNoHeader
      topMiddleContent={<BookAppointmentsSteps currentStep={currentStep} hasVerify={!!hadSurvey} />}
      changePageWarning={
        currentStep === BookingStep.ScheduleConfirmation && countDown > 0 && intlVerify('change_page_warning')
      }
    >
      {currentStep === BookingStep.Schedule && (
        <BookAppointmentsSchedule
          schedule={schedule}
          isFetchingSchedule={isFetchingSchedule}
          selectedSlots={selectedSlots}
          setSelectedSlots={setSelectedSlots}
          timezone={timezone}
          setTimezone={setTimezone}
          onConfirm={() => onBookVisits(true)}
          goToUnavailable={() => setCurrentStep(BookingStep.NotAvailable)}
        />
      )}
      {currentStep === BookingStep.ScheduleConfirmation && (
        <BookAppointmentsVerify
          schedule={schedule}
          selectedSlots={selectedSlots}
          timezone={timezone}
          onEdit={onEdit}
          onConfirm={() => onBookVisits(false)}
          countDown={countDown}
          setCountDown={setCountDown}
        />
      )}
      {currentStep === BookingStep.ScheduleSuccess && <BookAppointmentsEnroll />}
      {currentStep === BookingStep.NotAvailable && (
        <BookAppointmentsNotAvailable
          goBackToSchedule={() => setCurrentStep(BookingStep.Schedule)}
          scheduleId={schedule.id}
        />
      )}
      {showErrorPage && <BookAppointmentsErrorPage type={currentStep} />}
    </Layout>
  )
}
